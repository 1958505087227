import styled from "styled-components";
import { MOBILE_L_BP } from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
import { formatColor } from "@website-builder/utilities/utils/DesignUtils.js";

const baseBtnStyles = `
  padding: 0.625rem 1rem;
  border-radius: 0.25rem;
  font-family: var(--ff-haffer-xh);
  font-weight: var(--fw-haffer-xh-2);
  font-size: var(--fs-1);
  line-height: var(--lh-6);
  cursor: pointer;
  transition: background 0.2s, border 0.2s;

  @media only screen and (max-width: ${MOBILE_L_BP}px) {
    width: 100%;
  }
`;

export const PrimaryOnLight = styled.button`
	${baseBtnStyles}
	background: var(--secondary-purple-300);
	border: 1px solid var(--secondary-purple-300);
	color: var(--primary-neutral-white);

	${(props) => {
		if (props.primaryColorButton) {
			return `
        background: var(--theme-primary);
        border: 1px solid var(--theme-primary);
      `;
		}
	}}

	&:hover:enabled,
  &:focus:enabled {
		background: var(--secondary-purple-200);
		border: 1px solid var(--secondary-purple-200);
		${(props) => {
			if (props.primaryColorButton) {
				return `
          background: var(--theme-primary-light);
          border: 1px solid var(--theme-primary);
          color: var(--theme-light-font-color);
        `;
			}
		}}
	}

	&:disabled {
		background: var(--secondary-gray-medium);
		border: 1px solid var(--secondary-gray-medium);
		color: var(--secondary-gray-dark);
		cursor: not-allowed;
	}
`;

export const CustomButton = styled.button`
	${({
		buttonConfiguration: {
			backgroundColor,
			hoverBackgroundColor,
			fontColor,
			type = "primary",
		} = {},
	}) =>
		`
  ${baseBtnStyles}
  background: ${type === "secondary" ? "none" : formatColor(backgroundColor)};
  border: 1px solid ${formatColor(backgroundColor)};
  color: ${formatColor(fontColor)};

  &:hover:enabled,
  &:focus:enabled {
    background: ${formatColor(hoverBackgroundColor)};
    border: 1px solid ${formatColor(hoverBackgroundColor)};
  }
  `}

	&:disabled {
		background: var(--secondary-gray-medium);
		border: 1px solid var(--secondary-gray-medium);
		color: var(--secondary-gray-dark);
		cursor: not-allowed;
	}
`;

export const PrimaryOnDark = styled.button`
	${baseBtnStyles}
	background: var(--secondary-purple-100);
	border: 1px solid var(--secondary-purple-100);
	color: var(--secondary-purple-300);

	&:hover:enabled,
	&:focus:enabled {
		background: var(--secondary-purple-50);
		border: 1px solid var(--secondary-purple-50);
	}

	&:disabled {
		background: var(--secondary-gray-medium);
		border: 1px solid var(--secondary-gray-medium);
		color: var(--secondary-gray-dark);
		cursor: not-allowed;
	}
`;

export const SecondaryOnLight = styled.button`
	${baseBtnStyles}
	background: none;
	border: 1px solid var(--secondary-purple-300);
	color: var(--secondary-purple-300);

	${(props) => {
		if (props.primaryColorButton) {
			return `
        border: 1px solid var(--theme-primary);
        color: var(--theme-primary);
      `;
		}
	}}

	&:hover:enabled,
  &:focus:enabled {
		background: var(--secondary-purple-100);
		border: 1px solid var(--secondary-purple-100);

		${(props) => {
			if (props.primaryColorButton) {
				return `
          background: var(--theme-primary-pale);
          border: 1px solid var(--theme-primary-pale);
        `;
			}
		}}
	}

	&:disabled {
		background: none;
		border: 1px solid var(--secondary-gray-medium);
		color: var(--secondary-gray-dark);
		cursor: not-allowed;
	}
`;

export const SecondaryOnDark = styled.button`
	${baseBtnStyles}
	background: none;
	border: 1px solid var(--secondary-purple-100);
	color: var(--secondary-purple-100);

	&:hover:enabled,
	&:focus:enabled {
		background: var(--secondary-purple-100);
		border: 1px solid var(--secondary-purple-100);
		color: var(--primary-neutral-nightshade-800);
	}

	&:disabled {
		background: none;
		border: 1px solid var(--secondary-gray-medium);
		color: var(--secondary-gray-medium);
		cursor: not-allowed;
	}
`;
