import React, { forwardRef, useState, useContext } from "react";
import PropTypes from "prop-types";
import { ModalForm } from "@website-builder/ui/shared/elements";
import { Context } from "@website-builder/ui/shared";
import {
	PrimaryOnLight,
	PrimaryOnDark,
	SecondaryOnLight,
	SecondaryOnDark,
	CustomButton,
} from "./styles";
const isB2U = process.env.GATSBY_BUSINESS_VERTICAL === "b2u";
const Button = forwardRef(
	(
		{
			type,
			darkBg,
			buttonText,
			className,
			buttonConfiguration,
			modalConfiguration,
			...restProps
		},
		ref,
	) => {
		const { context = {}, formData = {} } = useContext(Context) || {};
		const [showProp, setShowProp] = useState(false);
		let buttonJSX = null;

		const customOnClick = () => {
			if (modalConfiguration.length) {
				setShowProp(true);
				restProps?.onClick?.();
			} else {
				restProps?.onClick?.();
			}
		};

		const mergedProps = {
			...restProps,
			onClick: customOnClick,
		};

		if (isB2U && buttonConfiguration) {
			buttonJSX = (
				<CustomButton
					ref={ref}
					{...mergedProps}
					buttonConfiguration={buttonConfiguration}
					className={`sb-custom-btn ${className}`}
				>
					{buttonText}
				</CustomButton>
			);
		} else if (type === "secondary") {
			if (darkBg) {
				buttonJSX = (
					<SecondaryOnDark
						ref={ref}
						{...mergedProps}
						className={`sb-secondary-btn ${className}`}
					>
						{buttonText}
					</SecondaryOnDark>
				);
			} else {
				buttonJSX = (
					<SecondaryOnLight
						ref={ref}
						{...mergedProps}
						className={`sb-secondary-btn ${className}`}
					>
						{buttonText}
					</SecondaryOnLight>
				);
			}
		} else {
			if (darkBg) {
				buttonJSX = (
					<PrimaryOnDark
						ref={ref}
						{...mergedProps}
						className={`sb-primary-btn ${className}`}
					>
						{buttonText}
					</PrimaryOnDark>
				);
			} else {
				buttonJSX = (
					<PrimaryOnLight
						ref={ref}
						{...mergedProps}
						className={`sb-primary-btn ${className}`}
					>
						{buttonText}
					</PrimaryOnLight>
				);
			}
		}
		const modalConfig = modalConfiguration?.[0];
		return (
			<>
				{buttonJSX}
				{showProp ? (
					<ModalForm
						hubspotFormContent={modalConfig}
						context={context}
						formData={formData}
						show={showProp}
						handleClose={() => setShowProp(false)}
						isDarkBg={modalConfig?.darkBg}
					/>
				) : null}
			</>
		);
	},
);

Button.defaultProps = {
	type: "primary",
	darkBg: false,
	buttonText: "",
	modalConfiguration: [],
	className: "",
};

Button.propTypes = {
	type: PropTypes.string,
	darkBg: PropTypes.bool,
	buttonText: PropTypes.string,
	modalConfiguration: PropTypes.array,
	className: PropTypes.string,
};

export default Button;
